
import Vue from "vue";
export default Vue.extend({
  props: {
    input: Object,
    initial : Boolean,
  },

  methods:{
      change(val:boolean){
        this.input.val = val
          this.$emit('input' , val)
      }
  },
});
