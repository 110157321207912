
import Vue from "vue";
export default Vue.extend({
  props:['input' , 'initial'],
  methods:{
    change(val:any){
      this.input.val = val

      // if (this.type == 'number') {
      //   val = parseInt(val)
      // }
      if(this.input.isNumber) {
        val = val == '' || val == null ? 0 : parseFloat(val)
      }
      this.$emit('input'  ,val)
    }
  }
});
